import { defineStore } from 'pinia';
import {useOrdersStore} from "@/console-new/stores/orders.store";
import {useUserStore} from "@/console-new/stores/user.store";
import API from "@/shared/helpers/API";
import {ordersEscpos} from "@/console-new/helpers/escpos";
import {useEpsonStore} from "@/console-new/stores/epson.store";
import {useCounterStore} from "@/console-new/stores/counter.store";
import {useLanguageStore, useNotificationStore} from "@/shared/stores";
import {usePollingStore} from "@/console-new/stores/polling.store";

export const useOrdersActionsStore = defineStore('ordersActions', {
	state: () => ({
		ordersPayment: null,
	}),
	getters: {
		isAllowActions: state => {
			let allowActions = true;
			if(typeof Android !== "undefined" && Android.getBooleanPreference) {
				return Android.getBooleanPreference("allowActions", true);
			}
			return allowActions;
		},
	},
	actions: {
		sendSms(ordersIds, callback) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			API.init().sendSms(userStore.currBusinessId, JSON.stringify(ordersIds), function (response) {
				if (response && response.result === 'OK') {
					callback && callback(true);
				} else {
					callback && callback(false);
				}
			});
		},
		sendOrderMessage(ordersIds, message, callback) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			API.init().addOrderMessage(userStore.currBusinessId, JSON.stringify(ordersIds), message, function (response) {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else {
					callback && callback(false);
				}
			});
		},
		ordersGroupAction(orderGroup) {

			let languageStore = useLanguageStore();
			let userStore = useUserStore();
			let ordersStore = useOrdersStore();

			if(orderGroup.status === ordersStore.STATUS.completed || orderGroup.status === ordersStore.STATUS.canceled || orderGroup.status === ordersStore.STATUS.failed) {
				return {
					text: languageStore.getString("completed"),
					actionId: "null",
					iconName: "icon-order-complete"
				};
			}

			let advancedPayments = !orderGroup.paid && userStore.currPlans.counter;

			if(orderGroup.status === ordersStore.STATUS.confirmed) {
				let refutable = ["delivery", "takeaway"].includes(orderGroup.order_mode);
				if(refutable) {
					return {
						text: languageStore.getString("accept"),
						actionId: "accept",
						iconName: "icon-order-accept"
					};
				} else {
					return {
						text: languageStore.getString("prepare"),
						actionId: "prepare",
						iconName: "icon-order-prepare"
					};
				}
			} else if(orderGroup.status === ordersStore.STATUS.accepted) {
				return {
					text: languageStore.getString("prepare"),
					actionId: "prepare",
					iconName: "icon-order-prepare"
				};
			} else if(orderGroup.status === ordersStore.STATUS.preparing) {
				if(userStore.currBusiness.counter_courses) {
					let lastCourse = orderGroup.orders.every(order => order.course >= order.maxCourse);
					if(lastCourse) {
						return {
							text: advancedPayments ? languageStore.getString("payment") : languageStore.getString("completed"),
							actionId: advancedPayments ? "payment" : "complete",
							iconName: advancedPayments ? "icon-payment-white" : "icon-order-complete"
						};
					} else {
						return {
							text: languageStore.getString("next_course"),
							actionId: "next",
							iconName: "icon-order-prepare"
						};
					}
				} else {
					return {
						text: advancedPayments ? languageStore.getString("payment") : languageStore.getString("completed"),
						actionId: advancedPayments ? "payment" : "complete",
						iconName: advancedPayments ? "icon-payment-white" : "icon-order-complete"
					};
				}
			} else if(orderGroup.status === ordersStore.STATUS.prepared) {
				return {
					text: orderGroup.paid ? languageStore.getString("completed") : languageStore.getString("payment"),
					actionId: advancedPayments ? "payment" : "complete",
					iconName: advancedPayments ? "icon-payment-white" : "icon-order-complete"
				};
			} else {
				return {
					text: languageStore.getString("unknown"),
					actionId: "null",
					iconName: "icon-order-complete"
				};
			}

		},
		performAction(actionId, orderGroup, callback) {

			let newStatus = null;
			let removeSearchIfNeeded = false;
			if(actionId === "accept") {
				newStatus = "accepted";
			} else if(actionId === "prepare") {
				newStatus = "preparing";
			} else if(actionId === "prepare_done") {
				newStatus = "prepared";
			} else if(actionId === "complete") {
				newStatus = "completed";
				removeSearchIfNeeded = true;
			} else if(actionId === "cancel") {
				newStatus = "canceled";
				removeSearchIfNeeded = true;
			} else if(actionId === "payment") {
				this.ordersPayment = orderGroup.orders;
				callback && callback(true, null);
				return;
			} else if(actionId === "next") {
				newStatus = "preparing";
			}

			let ordersIds = [];
			orderGroup.orders.forEach(order => {
				ordersIds.push(order.order_id);
			});
			this.changeOrders(ordersIds, actionId, (success, details) => {
				if(success) {


					const ordersStore = useOrdersStore();

					if(removeSearchIfNeeded) {

						let search = ordersStore.search;
						if(search && (search.startsWith("id:") || search.startsWith("tid:"))) {
							ordersStore.showCompleted = false;
							ordersStore.search = null;
							ordersStore.page = 0;
							ordersStore.refreshOrders();
							callback && callback(success, details);
							return;
						}

					}

					let updatesArray = [];
					orderGroup.orders.forEach(order => {
						let update = {
							order_id: order.order_id,
							status: newStatus,
						}
						if(actionId === "next" && details) {
							update.course = Number(details);
						}
						updatesArray.push(update);
					});
					ordersStore.applyOrdersUpdates(updatesArray, false);

				}
				callback && callback(success, details);
			});
		},
		performActionSingleOrder(actionId, order, callback) {

			let newStatus = null;
			if(actionId === "accept") {
				newStatus = "accepted";
			} else if(actionId === "prepare") {
				newStatus = "preparing";
			} else if(actionId === "prepare_done") {
				newStatus = "prepared";
			} else if(actionId === "complete") {
				newStatus = "completed";
			} else if(actionId === "cancel") {
				newStatus = "canceled";
			} else if(actionId === "payment") {
				this.ordersPayment = [order];
				callback && callback(true);
				return;
			} else if(actionId === "next") {
				newStatus = "preparing";
			}

			this.changeOrders([order.order_id], actionId, (success, details) => {
				if(success) {

					let updatesArray = [];
					let update = {
						order_id: order.order_id,
						status: newStatus,
					};
					if(actionId === "next" && details) {
						update.course = Number(details);
					}
					updatesArray.push(update);
					const ordersStore = useOrdersStore();
					ordersStore.applyOrdersUpdates(updatesArray, false);

				}
				callback && callback(success);
			});
		},
		changeOrders(ordersIds, action, callback) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			API.init().changeOrders(userStore.currBusinessId, JSON.stringify(ordersIds), action, resp => {
				if (resp && resp.result === "OK") {
					callback && callback(true, resp.new_course ? resp.new_course : null);
				} else {
					callback && callback(false, resp.details ? resp.details : null);
				}
			});
		},
		printAggregateOrder(orderGroup, callback) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}

			let orderIds = [];
			if(orderGroup) {
				orderGroup.orders.forEach(order => {
					orderIds.push(order.order_id);
				});
			}

			API.init().printAggregateReceipt(userStore.currBusinessId, JSON.stringify(orderIds), (response) => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else if (response && response.result === "KO" && response.details === "no_printers_found") {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification('no-printers-found');
					callback && callback(false);
				} else {
					if(typeof Android === 'undefined') {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("generic-fail");
					}
					callback && callback(false);
				}
			});
			if(typeof Android !== 'undefined' && Android.print) {
				let resultToPrint = ordersEscpos(orderGroup.orders);
				if(resultToPrint !== null && resultToPrint !== "") {
					Android.print(resultToPrint);
				}
			}
		},
		printPrebill(orderGroup, callback) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}

			let orderIds = [];
			if(orderGroup) {
				orderGroup.orders.forEach(order => {
					orderIds.push(order.order_id);
				});
			}

			API.init().printPrebill(userStore.currBusinessId, JSON.stringify(orderIds), (response) => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else if (response && response.result === "KO" && response.details === "no_printers_found") {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification('no-printers-found');
					callback && callback(false);
				} else {
					if(typeof Android === 'undefined') {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("generic-fail");
					}
					callback && callback(false);
				}
			});
			/* TODO prebill for android
			if(typeof Android !== 'undefined' && Android.print) {
				let resultToPrint = ordersEscpos(orderGroup.orders);
				if(resultToPrint !== null && resultToPrint !== "") {
					Android.print(resultToPrint);
				}
			}*/
		},
		paymentGroupDone(paymentType, amountPartial, itemsSplit, orderAssociatedId, callback) {

			if(!this.ordersPayment) {
				callback && callback(false);
				return;
			}
			const ordersStore = useOrdersStore();
			let orderIds = this.ordersPayment.map(o => o.order_id);
			let currentAction = this.ordersGroupAction(ordersStore.selectedOrderGroup);

			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				callback && callback(false);
				return;
			}

			const counterStore = useCounterStore();
			let invoiceData = null;
			if (counterStore.selectedCustomer !== null) {
				invoiceData = JSON.stringify({
					customer_id: counterStore.selectedCustomer.customer_id,
					customer_type: counterStore.selectedCustomer.type,
				});
			}

			if(orderAssociatedId) {

				//Webhook does the job
				ordersStore.refreshOrders((success) => {
					callback && callback(success);
				});

			} else {

				if(!amountPartial) {


					API.init().changeOrdersPayment(userStore.currBusinessId, JSON.stringify(orderIds), paymentType, invoiceData, resp => {
						if (resp && resp.result === "OK") {

							if (invoiceData && invoiceData.customer_type === "company" && paymentType !== "paylater" && paymentType !== "manual") {
								const notificationStore = useNotificationStore();
								if (!resp.invoice || resp.invoice.error !== null) {
									notificationStore.showNotification('invoice-request-failed');
								} else {
									notificationStore.showNotification('invoice-request-success');
								}
							}

							let updatesArray = [];
							orderIds.forEach(orderId => {
								updatesArray.push({
									order_id: orderId,
									paid: "1",
									status: currentAction && currentAction.actionId === 'next' && userStore.currBusiness.counter_courses ? 'preparing' : "completed",
									type: paymentType,
								});
								let orderPayment = this.ordersPayment ? this.ordersPayment.find(o => o.order_id === orderId) : null;
								if(orderPayment) {
									orderPayment.type = paymentType;
									orderPayment.paid = true;
								}
							});

							ordersStore.applyOrdersUpdates(updatesArray, false, null, (refreshed) => {

								let search = ordersStore.search;
								const epsonStore = useEpsonStore();
								if(search && (search.startsWith("id:") || search.startsWith("tid:"))) {
									ordersStore.showCompleted = false;
									ordersStore.search = null;
									ordersStore.page = 0;
									if(refreshed) {
										if(epsonStore.hasFiscalPrinterAutoMode) {
											const pollingStore = usePollingStore();
											pollingStore.forcePoll();
										}
									} else {
										ordersStore.refreshOrders(() => {
											if(epsonStore.hasFiscalPrinterAutoMode) {
												const pollingStore = usePollingStore();
												pollingStore.forcePoll();
											}
										});
									}
								} else {

									if(epsonStore.hasFiscalPrinterAutoMode) {
										const pollingStore = usePollingStore();
										pollingStore.forcePoll();
									}

								}

								callback && callback(true);

							});


						} else {
							callback && callback(false);
						}
					});

				} else {

					this.addOrderMultiPayment(orderIds, paymentType, amountPartial, itemsSplit, resp => {
						if(resp === "success") {

							const ordersStore = useOrdersStore();
							ordersStore.refreshOrders((success) => {
								callback && callback(success);
								if(success) {

									const epsonStore = useEpsonStore();
									if(epsonStore.hasFiscalPrinterAutoMode) {
										const pollingStore = usePollingStore();
										pollingStore.forcePoll();
									}

								}
							});

						} else if(resp === "invalid_enums") {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification('invalid-payment-method');
							callback && callback(false);
						} else {
							callback && callback(false);
						}
					});

				}

			}

		},
		addOrderMultiPayment(orderIds, type, amountPaidPartial, itemsSplit, callback = null) {

			let userStore = useUserStore();
			API.init().addOrderMultiPayment(userStore.currBusinessId, JSON.stringify(orderIds), type, amountPaidPartial, JSON.stringify(itemsSplit), r => {
				if(r && r.result) {
					if(r.result === "OK") {
						callback && callback("success");
					} else if(r.details) {
						callback && callback(r.details);
					} else {
						callback && callback("error");
					}
				} else {
					callback && callback("error");
				}
			});

		},
	}
});







