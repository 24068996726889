import {defineStore} from 'pinia';
import {useMenuStore, useOrdersStore, useUserStore} from "@/console-new/stores";

import API from '@/shared/helpers/API';
import {useLanguageStore, useNotificationStore} from "@/shared/stores";
import {formatDateTime} from "@/shared/helpers/dates";
import {getRandomAlphanumericString} from "@/shared/helpers/strings";

export const useCounterStore = defineStore('counter', {
	state: () => ({
		fakeItems: [],
		cartItems: [],
		compositeProducts: [],
		currComposite: null,
		loadingComposite: false,
		customerNotEditable: false,
		noPayLater: false,
		subtotalCosts: 0,
		coverCharge: 0,
		coverNum: 0,
		deliveryCosts: 0,
		takeawayCosts: 0,
		editingItem: null,
		paymentMethod: null,
		selectedCustomer: null,
		selectedTable: null,
		selectedMode: "counter",
		deliveryAddress: null,
		customerName: null,
		customerPhone: null,
		customerEmail: null,
		notInPlaceDate: null,
		orderNotes: null,
		search: null,
		mostSold: null,
		editOrder: null,
		selectedCourse: 1,
		maxCourse: 1,
		tablesActive: false,
		paymentActive: false,
	}),
	getters: {
		runningCourse: state => {
			if (state.editOrder) {
				return state.editOrder.course ? state.editOrder.course : null;
			} else {
				return null;
			}
		},
		cartTotal: state => {
			let cartItems = state.cartItems.reduce((acc, curr) => {
				return acc + curr.quantity * (curr.price + curr.products.reduce((acc2, curr) => {
					return acc2 + curr.price * curr.quantity;
				}, 0));
			}, 0);
			let fakeItems = state.fakeItems.reduce((acc, curr) => {
				return acc + curr.price * curr.quantity;
			}, 0);
			return cartItems + fakeItems;
		},
		additionalCosts: state => {
			return (state.coverCharge * state.coverNum) + state.deliveryCosts + state.takeawayCosts + state.subtotalCosts;
		},
		totalNoCover: state => {
			return state.cartTotal + state.deliveryCosts + state.takeawayCosts + state.subtotalCosts;
		},
		grandTotal: state => {
			return state.cartTotal + state.additionalCosts;
		},
		cartItemsCount: state => {
			let cartItems = state.cartItems.reduce((acc, curr) => {
				return acc + curr.quantity + curr.products.reduce((acc, curr) => {
					return acc + curr.quantity;
				}, 0);
			}, 0);
			let fakeItems = state.fakeItems.reduce((acc, curr) => {
				return acc + curr.quantity;
			}, 0);
			return cartItems + fakeItems;
		},
		modeTitle: state => {
			const languageStore = useLanguageStore();
			if (state.selectedMode === "counter") {
				return languageStore.getString("direct_sale");
			} else if (state.selectedMode === "qr") {
				return state.selectedTable ? state.selectedTable.table_name : "-";
			} else if (state.selectedMode === "delivery") {
				return languageStore.getString("delivery");
			} else if (state.selectedMode === "takeaway") {
				const userStore = useUserStore();
				return userStore.takeawayWord;
			} else {
				return "-";
			}
		},
		withCourses: state => {
			const userStore = useUserStore();
			if (!userStore.currBusiness || !userStore.currBusiness.counter_courses) {
				return false;
			}
			let noCoursesModes = ["delivery", "takeaway"];
			if (state.editOrder) {
				return !noCoursesModes.includes(state.editOrder.order_mode);
			} else {
				return !noCoursesModes.includes(state.selectedMode);
			}
		}
	},
	actions: {
		removeUnusedCourses(greaterThan) {
			for (let i = this.maxCourse; i > greaterThan; i--) {
				let index = this.cartItems.find(item => item.course === i);
				if (!index) {
					this.maxCourse--;
				} else {
					break;
				}
			}
			if (this.selectedCourse > this.maxCourse) {
				this.selectedCourse = this.maxCourse;
			}
		},
		startEditOrder(order) {
			this.editOrder = order;
			let firstCourseWithItems = null;
			let maxCourse = 1;
			this.cartItems = order.items.map(item => {
				if (!firstCourseWithItems || item.course < firstCourseWithItems) {
					firstCourseWithItems = item.course;
				}
				if (item.course > maxCourse) {
					maxCourse = item.course;
				}
				return {
					identifier: getRandomAlphanumericString(10),
					order_item_id: item.order_item_id,
					menu_item_id: Number(item.item_id),
					name: item.name,
					price: item.price,
					menu_item: item.menu_item,
					quantity: item.quantity,
					note: item.note,
					products: item.products.map(product => {
						return {
							menu_item_list_product_id: product.menu_item_list_product_id,
							product_name: product.product_name,
							price: product.price,
							quantity: product.quantity,
						}
					}),
					course: item.course,
				}
			});
			this.subtotalCosts = order.subtotal_delta;
			this.orderNotes = order.notes;
			this.coverNum = order.cover_charge_num ? order.cover_charge_num : 0;
			this.coverCharge = order.cover_charge_value ? order.cover_charge_value : 0;
			this.deliveryCosts = order.delivery_costs_value ? order.delivery_costs_value : 0;
			this.takeawayCosts = order.takeaway_costs_value ? order.takeaway_costs_value : 0;
			this.selectedCourse = firstCourseWithItems ? firstCourseWithItems : 1;
			this.maxCourse = maxCourse;
			if (this.runningCourse) {
				this.selectedCourse = this.runningCourse;
				if (this.runningCourse > this.maxCourse) {
					this.maxCourse = this.runningCourse;
				}
			}
		},
		finishEditOrder() {
			this.editOrder = null;
			this.clearCart();
		},
		clearCart() {
			this.cartItems = [];
			this.fakeItems = [];
			this.compositeProducts = [];
			this.subtotalCosts = 0;
			this.paymentMethod = null;
			this.selectedMode = "counter";
			this.selectedTable = null;
			this.coverCharge = 0;
			this.coverNum = 0;
			this.deliveryCosts = 0;
			this.takeawayCosts = 0;
			this.selectedCourse = 1;
			this.maxCourse = 1;
			this.deliveryAddress = null;
			this.customerName = null;
			this.customerPhone = null;
			this.customerEmail = null;
			this.notInPlaceDate = null;
			this.orderNotes = null;
		},
		addProduct(product) {
			const id = product.menu_item_list_product_id;
			const index = this.compositeProducts.findIndex(item => item.menu_item_list_product_id === id);
			if (index === -1) {
				let prodName = product.product_name;
				let prodPrice = product.product_price;
				if(!product.meta_product && product.menu_item_linked_id && (product.linked_price || product.linked_name)) {
					let linkedProduct = useMenuStore().modItemFromId(product.menu_item_linked_id);
					prodName = product.linked_name && linkedProduct ? linkedProduct.name : prodName;
					prodPrice = product.linked_price && linkedProduct ? linkedProduct.price : prodPrice;
				}

				this.compositeProducts.push({
					menu_item_list_product_id: product.menu_item_list_product_id,
					product_name: prodName,
					price: prodPrice,
					quantity: 1
				});
			} else {
				this.compositeProducts[index].quantity++;
			}
		},
		removeProduct(product) {
			const id = product.menu_item_list_product_id;
			const index = this.compositeProducts.findIndex(item => item.menu_item_list_product_id === id);
			if (index !== -1) {
				if (this.compositeProducts[index].quantity > 1) {
					this.compositeProducts[index].quantity--;
				} else {
					this.compositeProducts.splice(index, 1);
				}
			}
		},
		getProductQuantity(product) {
			const id = product.menu_item_list_product_id;
			const index = this.compositeProducts.findIndex(item => item.menu_item_list_product_id === id);
			if (index !== -1) {
				return this.compositeProducts[index].quantity;
			}
			return 0;
		},
		getCartItemQuantity(item) {
			const id = item.menu_item_id;
			return this.cartItems.reduce((acc, curr) => {
				if (curr.menu_item_id === id) {
					return acc + curr.quantity;
				}
				return acc;
			}, 0);
		},
		addCartItem(item) {
			if (item.composite) {
				this.currComposite = item;
				if (!item.compositeLoaded) {
					this.loadingComposite = true;
					let menuStore = useMenuStore();
					menuStore.getAllCompositeInfo(item.menu_item_id, (result) => {
						if (!result) {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification('composed-no-items');
						}
						this.loadingComposite = false;
					});
				}
				return;
			}
			const id = Number(item.menu_item_id);
			const index = this.cartItems.findIndex(item => item.menu_item_id === id && item.course === this.selectedCourse && item.note === null);
			if (index === -1) {
				this.cartItems.push({
					identifier: getRandomAlphanumericString(10),
					menu_item_id: id,
					name: item.name,
					price: item.price,
					menu_item: {
						name: item.name,
						picture: item.picture,
					},
					quantity: 1,
					note: null,
					products: [],
					course: this.selectedCourse,
				});
			} else {
				this.cartItems[index].quantity++;
			}
		},
		splitItem(item) {
			const id = item.identifier;
			const index = this.cartItems.findIndex(item => item.identifier === id);
			if (index !== -1 && this.cartItems[index].quantity > 1) {
				let cloneItem = {
					...this.cartItems[index],
					identifier: getRandomAlphanumericString(10),
					quantity: 1,
				}
				this.cartItems[index].quantity--;
				this.cartItems.push(cloneItem);
			}
		},
		addCartFakeItem(value) {

			let randomId = getRandomAlphanumericString(10);
			this.fakeItems.push({
				identifier: randomId,
				price: value,
				quantity: 1,
			});
			return randomId;

		},
		removeCartItem(item, quantity = 1) {
			let index = -1;
			if (item.identifier) {
				const id = item.identifier;
				index = this.cartItems.findIndex(item => item.identifier === id);
			} else {
				const id = item.menu_item_id;
				index = this.cartItems.findIndex(item => item.menu_item_id === id);
			}
			if (index !== -1) {
				if (this.cartItems[index].quantity > quantity) {
					this.cartItems[index].quantity -= quantity;
				} else {
					this.cartItems.splice(index, 1);

					if (this.withCourses) {
						this.removeUnusedCourses(1);
					}
				}
			}
		},
		changeQuantityCartFakeItem(item, quantity = 1) {
			const id = item.identifier;
			const index = this.fakeItems.findIndex(item => item.identifier === id);
			if (index !== -1) {
				if (quantity <= 0) {
					this.fakeItems.splice(index, 1);
				} else {
					this.fakeItems[index].quantity = quantity;
				}
			}
		},
		changePriceCartFakeItem(item, price) {
			const id = item.identifier;
			const index = this.fakeItems.findIndex(item => item.identifier === id);
			if (index !== -1) {
				this.fakeItems[index].price = price;
			}
		},
		addCompositeItem() {
			if (this.currComposite) {
				this.cartItems.push({
					identifier: getRandomAlphanumericString(10),
					menu_item_id: Number(this.currComposite.menu_item_id),
					name: this.currComposite.name,
					price: this.currComposite.price,
					menu_item: {
						name: this.currComposite.name,
						picture: this.currComposite.picture,
					},
					quantity: 1,
					note: null,
					products: this.compositeProducts,
					course: this.selectedCourse,
				});
				this.currComposite = null;
				this.compositeProducts = [];
			}
		},
		getMostSoldProducts() {
			let userStore = useUserStore();
			let mostSoldStorage = localStorage.getItem("most-sold");
			if (localStorage.getItem("most-sold-business-id") && (parseInt(localStorage.getItem("most-sold-business-id")) === parseInt(userStore.currBusinessId)) && mostSoldStorage) {
				this.mostSold = JSON.parse(mostSoldStorage);
			} else {
				localStorage.removeItem("most-sold");
				localStorage.removeItem("most-sold-business-id");
				API.init().getMostSoldProducts(userStore.currBusinessId, res => {
					if (res && res["result"] && res["result"] === "OK") {

						let mostSoldItems = {};
						res.most_sold.forEach((item) => {
							mostSoldItems[item.item_id] = parseInt(item.total);
						});

						this.mostSold = mostSoldItems;
						localStorage.setItem("most-sold", JSON.stringify(mostSoldItems));
						localStorage.setItem("most-sold-business-id", parseInt(userStore.currBusinessId));
					} else {
						console.warn("getMostSoldProducts failed");
					}
				});
			}
		},
		updateMostSoldProducts(items) {
			let newMostSold = [];
			let localMostSold = localStorage.getItem("most-sold");
			if (localMostSold) {
				newMostSold = JSON.parse(localMostSold);
				items.forEach(item => {
					if (newMostSold.hasOwnProperty(item.menu_item_id)) {
						newMostSold[item.menu_item_id] += item.quantity;
					} else {
						newMostSold[item.menu_item_id] = item.quantity;
					}
				});
			} else {
				items.forEach(item => {
					newMostSold[item.menu_item_id] = item.quantity;
				});
			}
			localStorage.setItem("most-sold", JSON.stringify(newMostSold));
			this.mostSold = newMostSold;
		},
		createOrder(amountPaidPartial, itemsSplit, requestingPayment, callback = null) {

			if (this.selectedMode === "delivery" && this.deliveryAddress) {
				if (this.deliveryAddress.hasOwnProperty("address_id")) {
					this.createOrderContinue(this.deliveryAddress.address_id, amountPaidPartial, itemsSplit, requestingPayment, callback);
				} else {
					this.addAddress((addressId) => {
						if (addressId) {
							this.createOrderContinue(addressId, amountPaidPartial, itemsSplit, requestingPayment, callback);
						} else {
							callback && callback("error", null);
						}
					});
				}
			} else {
				this.createOrderContinue(null, amountPaidPartial, itemsSplit, requestingPayment, callback);
			}

		},
		createOrderContinue(addressId, amountPaidPartial, itemsSplit, requestingPayment, callback = null) {

			let userStore = useUserStore();
			let items = this.cartItems.map(item => {
				let redeemed = 0;
				if (itemsSplit) {
					let splitItem = itemsSplit.find(split => split.menu_item_id === item.menu_item_id);
					if (splitItem) {
						redeemed = splitItem.quantity;
					}
				}
				return {
					menu_item_id: item.menu_item_id,
					quantity: item.quantity,
					price: item.price,
					notes: item.note,
					redeemed: redeemed,
					products: item.products.map(product => {
						return {
							menu_item_list_product_id: product.menu_item_list_product_id,
							quantity: product.quantity,
							product_price: product.price,
							nested: false,
						}
					}),
					course: this.withCourses ? item.course : 1,
				}
			});
			let paymentMethod = this.paymentMethod ? this.paymentMethod : "cash";
			if (requestingPayment) {
				paymentMethod = "stripe";
			}
			let paid = !!this.paymentMethod && amountPaidPartial === null && !requestingPayment;
			let roomTableId = this.selectedMode === "qr" && this.selectedTable ? this.selectedTable.room_table_id : null;
			let deliveryAddressId = this.selectedMode === "delivery" ? addressId : null;
			let isNotInPlace = this.selectedMode === "delivery" || this.selectedMode === "takeaway";
			let customerName = isNotInPlace ? this.customerName : null;
			let customerPhone = isNotInPlace ? this.customerPhone : null;
			let customerEmail = isNotInPlace ? this.customerEmail : null;
			let notInPlaceDate = isNotInPlace ? formatDateTime(this.notInPlaceDate) : null;
			let fakeItemsTotal = this.fakeItems.reduce((acc, curr) => {
				return acc + curr.price * curr.quantity;
			}, 0);
			let ordersStore = useOrdersStore();
			let status = paid && userStore.currBusiness.autocomplete_payed_counter && this.selectedMode === "counter" ? ordersStore.STATUS.completed : ordersStore.STATUS.preparing;
			if (requestingPayment) {
				status = ordersStore.STATUS.unconfirmed;
			}
			let invoiceData = null;
			if (this.selectedCustomer) {
				invoiceData = JSON.stringify({
					customer_id: this.selectedCustomer.customer_id,
					customer_type: this.selectedCustomer.type,
				});
				if (!customerName) {
					customerName = this.selectedCustomer.name;
				}
			}
			let coverPaid = null;
			if (this.coverNum > 0 && itemsSplit) {
				itemsSplit.forEach(item => {
					if(item.order_item_id && item.order_item_id === "cover") {
						coverPaid = item.quantity;
					}
				});
				if(coverPaid && coverPaid > this.coverNum) {
					coverPaid = this.coverNum;
				}
			}
			API.init().addOrderCounter(userStore.currBusinessId, this.selectedMode, JSON.stringify(items), this.subtotalCosts + fakeItemsTotal, this.orderNotes, customerName, customerEmail, customerPhone, roomTableId, deliveryAddressId, this.coverNum, this.coverCharge, coverPaid, this.takeawayCosts, this.deliveryCosts, status, paymentMethod, paid, notInPlaceDate, amountPaidPartial, invoiceData, userStore.unlockUserId, r => {
				if (r && r.result) {
					if (r.result === "OK" && r.order_id) {

						if (!requestingPayment) {

							this.selectedCustomer = null;
							this.clearCart();
							this.updateMostSoldProducts(items);

						}
						callback && callback("success", r.order_id);
					} else if (r.details) {
						callback && callback(r.details, null);
					} else {
						callback && callback("error", null);
					}
				} else {
					callback && callback("error", null);
				}
			});

		},
		performEditOrder(callback = null) {

			if (!this.editOrder) {
				callback && callback(false);
				return;
			}

			let userStore = useUserStore();
			let items = this.cartItems.map(item => {
				return {
					order_item_id: item.order_item_id ? item.order_item_id : null,
					menu_item_id: item.menu_item_id,
					quantity: item.quantity,
					price: item.price,
					note: item.note,
					notes: item.note,
					name: item.menu_item ? item.menu_item.name : null,
					products: item.products.map(product => {
						return {
							menu_item_list_product_id: product.menu_item_list_product_id,
							order_item_id: product.order_item_id,
							quantity: product.quantity,
							product_price: product.price,
							price: product.price,
							product_name: product.product_name,
							nested: false,
						}
					}),
					course: this.withCourses ? item.course : 1,
				}
			});
			let fakeItemsTotal = this.fakeItems.reduce((acc, curr) => {
				return acc + curr.price * curr.quantity;
			}, 0);
			API.init().editOrder(userStore.currBusinessId, this.editOrder.order_id, this.editOrder.room_table_id, JSON.stringify(items), this.subtotalCosts + fakeItemsTotal, this.orderNotes, this.coverNum, this.coverCharge, this.takeawayCosts, this.deliveryCosts, r => {
				const notificationStore = useNotificationStore();
				if (r && r.result && r.result === "OK") {
					callback && callback(true);
				} else {
					callback && callback(false);
					let errDetails = r && r.details ? r.details : '';
					let notificationName = errDetails === 'order_paid' ? 'edit-order-error-paid' : 'generic-fail';
					notificationName = errDetails === 'order_partially_paid' ? 'edit-order-error-partially-paid' : notificationName;
					notificationStore.showNotification(notificationName);
				}
			});


		},
		addAddress(callback) {
			if (!this.deliveryAddress) {
				callback(null);
				return;
			}
			const userStore = useUserStore();
			API.init().addNewAddress(userStore.currBusinessId, this.deliveryAddress.address, this.deliveryAddress.lat, this.deliveryAddress.lng, JSON.stringify(this.deliveryAddress.data), "order", response => {
				if (response && response.result === "OK" && response.address_id) {
					callback(response.address_id);
				} else {
					callback(null);
				}
			});
		},
	},
});
