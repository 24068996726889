import {useEpsonStore, useOrdersStore} from "@/console-new/stores";

export const Epson = {
	queue: [],
	isPrinting: false,
	timeoutPrint: null,
	queueEmptyCallback: null,
	addToQueue: (queueOrder, retrying) => {
		if(retrying) {
			Epson.retryIds(JSON.parse(queueOrder.ids));
		}
		Epson.queue.push(queueOrder);
		if (!Epson.isPrinting) {
			Epson.printQueue();
		}
	},
	printQueue: () => {
		if(typeof Android === "undefined" || Epson.isPrinting) {
			return;
		}
		if(Epson.queue.length === 0) {
			Epson.queueEmptyCallback && Epson.queueEmptyCallback();
			Epson.queueEmptyCallback = null;
			return;
		}
		Epson.isPrinting = true;
		let currentElement = Epson.queue.shift();
		if(currentElement) {

			let parsedIds = JSON.parse(currentElement.ids);
			if(Epson.areIdsSent(parsedIds)) {
				Epson.printDone(false);
			} else {
				let epsonStore = useEpsonStore();
				epsonStore.setOrdersFiscalSent(parsedIds, (success) => {
					if(success) {
						Epson.saveSentIds(parsedIds);
						Android.printFiscalReceipt(currentElement.ids, currentElement.data, currentElement.email);
					} else {
						Epson.printDone(false);
					}
				});
				Epson.timeoutPrint = setTimeout(() => { Epson.isPrinting = false; }, 16000);
			}
		}
	},
	printDone: (wait = true) => {
		clearTimeout(Epson.timeoutPrint);
		if(wait) {
			setTimeout(() => {
				Epson.isPrinting = false;
				Epson.printQueue();
			}, 500);
		} else {
			Epson.isPrinting = false;
			Epson.printQueue();
		}
	},
	saveSentIds: (ids) => {
		let sentIds = localStorage.getItem("epsonSentIds");
		let currentIds = [];
		if(sentIds) {
			currentIds = JSON.parse(sentIds);
		}
		currentIds = currentIds.concat(ids);
		if(currentIds.length > 1000) {
			currentIds = currentIds.slice(currentIds.length - 1000);
		}
		localStorage.setItem("epsonSentIds", JSON.stringify(currentIds));
	},
	retryIds: (ids) => {
		let sentIds = localStorage.getItem("epsonSentIds");
		let currentIds = [];
		if(sentIds) {
			currentIds = JSON.parse(sentIds);
		}
		for(let i = 0; i < ids.length; i++) {
			let index = currentIds.indexOf(ids[i]);
			if(index !== -1) {
				currentIds.splice(index, 1);
			}
		}
		localStorage.setItem("epsonSentIds", JSON.stringify(currentIds));
	},
	areIdsSent: (ids) => {
		let sentIds = localStorage.getItem("epsonSentIds");
		if(sentIds) {
			let currentIds = JSON.parse(sentIds);
			for(let i = 0; i < ids.length; i++) {
				if(currentIds.indexOf(ids[i]) !== -1) {
					return true;
				}
			}
		}
		return false;
	}
}
