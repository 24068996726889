import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores";
import {getLocalDateFromServer} from "@/shared/helpers/dates";

export const useRoomsStore = defineStore('rooms', {
	state: () => ({
		rooms: null,
		tables: null,
		activeRoomId: null,
		editMode: false,
		reverseOrder: false,
		showFreeTables: true,
		showOccupiedTables: true,
	}),
	getters: {
		roomsTables: state => {
			if(!state.rooms || !state.tables) return null;
			let rooms = state.rooms;
			let tables = state.tables;
			for(let i = 0; i < rooms.length; i++) {
				rooms[i].tables = tables.filter(table => table.room_id === rooms[i].room_id);
			}
			return rooms;
		},
		roomsIds: state => {
			let finalRooms = {};
			if(!state.rooms) return finalRooms;
			for(let i = 0; i < state.rooms.length; i++) {
				finalRooms[state.rooms[i].room_id] = state.rooms[i];
			}
			return finalRooms;
		}
	},
	actions: {
		getBusinessRooms(callback = null, force = false) {
			let userStore = useUserStore();
			if(userStore.currBusinessId === null) return;
			if(!force) {
				if(this.roomsTables != null) {
					callback && callback(this.roomsTables); return;
				}
			}
			API.init().getRooms(userStore.currBusinessId, response => {
				if (response && response.result === "OK") {
					if(response.rooms && response.rooms_tables) {
						this.normalizeRooms(response.rooms);
						this.normalizeTables(response.rooms_tables);
						this.rooms = response.rooms;
						this.tables = response.rooms_tables;
						let startRoomId = localStorage.getItem('start_room_id');
						if(startRoomId && this.rooms.find(room => room.room_id === Number(startRoomId))) {
							this.activeRoomId = Number(startRoomId);
						} else if(this.rooms.length > 0) {
							this.activeRoomId = this.rooms[0].room_id;
							localStorage.setItem('start_room_id', this.activeRoomId+"");
						} else {
							this.activeRoomId = null;
							localStorage.removeItem('start_room_id');
						}
					}
					callback && callback(this.roomsTables);
				}
			});
		},
		updateRoomStatus(room, callback = null) {
			const userStore = useUserStore();
			API.init().getRoomStatus(userStore.currBusinessId, room.room_id, (r) => {
				if(r && r.result && r.result === "OK" && r.orders) {
					room.tables.forEach(table => {
						table.currentTotal = 0;
						table.current_course = null;
						table.ordersNum = 0;
						table.openDate = null;
						if(r.orders.hasOwnProperty(table.room_table_id)) {

							let maxCourse = null;
							let openTableDate = null;
							let total = 0;
							let prebillRequested = false;
							let coversNum = 0;
							r.orders[table.room_table_id].forEach(order => {
								if(!maxCourse || order.course > maxCourse) {
									maxCourse = order.course;
								}
								let orderDate = getLocalDateFromServer(order.date);
								if(!openTableDate || orderDate < openTableDate) {
									openTableDate = orderDate;
								}
								let coverNum = Number(order.cover_charge_num);
								coversNum += coverNum;
								total += Number(order.total) + Number(order.subtotal_delta) + (Number(order.cover_charge_value) * coverNum);
								prebillRequested = prebillRequested || order.prebill_requested === "1";
							});

							if(userStore.currBusiness.counter_courses && maxCourse) {
								table.current_course = maxCourse;
							}
							table.currentTotal = total;
							table.openDate = openTableDate;
							table.prebillRequested = prebillRequested && openTableDate;
							table.coversNum = coversNum;
							table.ordersNum = r.orders[table.room_table_id].length;

						}
					});
					callback && callback();
				}
			});
		},
		setActiveRoom(roomId) {
			this.activeRoomId = roomId;
			localStorage.setItem('start_room_id', roomId+"");
		},
		normalizeRooms(rooms) {
			for(let i = 0; i < rooms.length; i++) {
				rooms[i].room_id = Number(rooms[i].room_id);
				rooms[i].business_id = Number(rooms[i].business_id);
				rooms[i].acceptOrders = rooms[i].accept_orders === "1";
				rooms[i].enabledCalls = rooms[i].enabled_calls === "1";
				rooms[i].enablePayBill = rooms[i].enable_pay_bill === "1";
				rooms[i].forcePayBill = rooms[i].force_pay_bill === "1";
				rooms[i].notification_sound = rooms[i].notification_sound || null;
			}
		},
		normalizeTables(tables) {
			for(let i = 0; i < tables.length; i++) {
				tables[i].room_table_id = Number(tables[i].room_table_id);
				tables[i].room_id = Number(tables[i].room_id);
				tables[i].business_id = Number(tables[i].business_id);
				tables[i].order_number = Number(tables[i].order_number);
				tables[i].seat_number = tables[i].seat_number ? Number(tables[i].seat_number) : null;
				tables[i].terminal_id = tables[i].terminal_id ? Number(tables[i].terminal_id) : null;
				tables[i].current_course = null;
				tables[i].ordersNum = null;
				tables[i].editName = false;
				tables[i].qrCode = null;
				tables[i].openDate = null;
				tables[i].timeElapsedOpen = null;
				tables[i].prebillRequested = false;
				tables[i].currentTotal = 0;
				tables[i].coversNum = null;
			}
		},
		requestQrPrint(type, quantity, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId === null) return;
			API.init().requestQrPrint(userStore.currBusinessId, type, quantity, response => {
				if (response && response.result === "OK") {
					callback && callback(true);
				} else {
					callback && callback(false);
				}
			});
		}
	}
});