import { defineStore } from 'pinia';
import {useUserStore} from "@/console-new/stores/user.store";
import API from "@/shared/helpers/API";
import {getLocalDateFromServer} from "@/shared/helpers/dates";
import {orderNumberDay} from "@/console-new/helpers/orders";

export const useCustomersStore = defineStore('customers', {
	state: () => ({
		customersPeople: [],
		customersPeopleTotal: 0,
		customersCompanies: [],
		customersCompaniesTotal: 0,
		personOrders: [],
		personOrdersTotal: 0,
		companyOrders: [],
		companyOrdersTotal: 0,
		companyPeople: [],
		companyPeopleTotal: 0,
	}),
	getters: {
		companiesIds: state => {
			let finalIds = {};
			if(!state.customersCompanies || !state.customersCompanies.length) { return finalIds; }
			state.customersCompanies.forEach(t => {
				finalIds[t.customer_id] = t;
			});
			return finalIds;
		}

	},
	actions: {
		getFilteredPeople(filters, callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); return; }

			API.init().getFilteredCustomersPeople(userStore.currBusinessId, JSON.stringify(filters || []), r => {
				this.customersPeople = [];
				this.customersPeopleTotal = 0;
				if(r && r.result === "OK") {
					if(r.customers && r.customers.selected && r.customers.selected.length) {
						r.customers.selected.forEach(t => {
							let normalized = this.normalizeCustomerPerson(t);
							if(!normalized.deleted) {
								this.customersPeople.push(normalized);
							}
						});
						this.customersPeopleTotal = Number(r.customers.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		normalizeCustomerPerson(customer) {
			customer.customer_id = customer.customer_id ? Number(customer.customer_id) : null;
			customer.address_id = customer.address_id ? Number(customer.address_id) : null;
			customer.fiscal_code = customer.fiscal_code || null;
			customer.name = customer.name || null;
			customer.birth_date = customer.birth_date ? new Date(customer.birth_date) : null;
			customer.phone = customer.phone || null;
			customer.email = customer.email || null;
			customer.gender = customer.gender || null;
			customer.notes = customer.notes || null;
			customer.balance = customer.balance ? Number(customer.balance) : 0;
			customer.deleted = customer.deleted === '1';
			return customer;
		},
		getFilteredCompanies(filters, callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); return; }

			API.init().getFilteredCustomersCompanies(userStore.currBusinessId, JSON.stringify(filters || []), r => {
				this.customersCompanies = [];
				this.customersCompaniesTotal = 0;
				if(r && r.result === "OK") {
					if(r.customers && r.customers.selected && r.customers.selected.length) {
						r.customers.selected.forEach(t => {
							let normalized = this.normalizeCustomerCompany(t);
							if(!normalized.deleted) {
								this.customersCompanies.push(normalized);
							}
						});
						this.customersCompaniesTotal = Number(r.customers.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		normalizeCustomerCompany(customer) {
			customer.customer_id = customer.customer_id ? Number(customer.customer_id) : null;
			customer.address_id = customer.address_id ? Number(customer.address_id) : null;
			customer.delivery_address_id = customer.delivery_address_id ? Number(customer.delivery_address_id) : null;
			customer.fiscal_code = customer.fiscal_code || null;
			customer.name = customer.name || null;
			customer.phone = customer.phone || null;
			customer.email = customer.email || null;
			customer.notes = customer.notes || null;
			customer.cf_fallback = customer.fiscal_code ? customer.fiscal_code : customer.vat;
			customer.balance = customer.balance ? Number(customer.balance) : 0;
			customer.deleted = customer.deleted === '1';
			return customer;
		},
		getFilteredPersonOrders(customerId, filters, callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); return; }

			API.init().getFilteredPersonOrders(userStore.currBusinessId, customerId, JSON.stringify(filters || []), r => {
				this.personOrders = [];
				this.personOrdersTotal = 0;
				if(r && r.result === "OK") {
					if(r.orders && r.orders.selected && r.orders.selected.length) {
						r.orders.selected.forEach(t => {
							this.personOrders.push(this.normalizeOrder(t));
						});
						this.personOrdersTotal = Number(r.orders.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		getFilteredCompanyOrders(customerId, filters, callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); return; }

			API.init().getFilteredCompanyOrders(userStore.currBusinessId, customerId, JSON.stringify(filters || []), r => {
				this.companyOrders = [];
				this.companyOrdersTotal = 0;
				if(r && r.result === "OK") {
					if(r.orders && r.orders.selected && r.orders.selected.length) {
						r.orders.selected.forEach(t => {
							this.companyOrders.push(this.normalizeOrder(t));
						});
						this.companyOrdersTotal = Number(r.orders.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
		normalizeOrder(order) {
			order.date = order.date ? getLocalDateFromServer(order.date) : null;
			order.order_number = orderNumberDay(order);
			order.order_id = order.order_id ? Number(order.order_id) : null;
			order.total = order.total ? Number(order.total) : 0;
			return order;
		},
		getFilteredCompanyPeople(customerId, filters, callback = null) {

			let userStore = useUserStore();
			if(!userStore.currBusinessId) { callback && callback(false); return; }

			API.init().getFilteredCompanyPeople(userStore.currBusinessId, customerId, JSON.stringify(filters || []), r => {
				this.companyPeople = [];
				this.companyPeopleTotal = 0;
				if(r && r.result === "OK") {
					if(r.people && r.people.selected && r.people.selected.length) {
						r.people.selected.forEach(t => {
							this.companyPeople.push(this.normalizeCustomerPerson(t));
						});
						this.companyPeopleTotal = Number(r.people.selected_num || 0);
					}
					if(callback) { callback(true); }
				} else {
					if(callback) { callback(false, r ? r.details : null); }
				}
			});
		},
	}
});







